import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import AboutPage from "./AboutPage";
import { aboutPages } from "../../constantData/aboutPagesData";

const About = () => {
  return (
    <Carousel
      autoPlay={true}
      interval={5000}
      infiniteLoop
      className="about component"
    >
      {aboutPages.map((page) => (
        <AboutPage
          bg={page.bg}
          icon={page.icon}
          heading={page.heading}
          desc={page.desc}
        />
      ))}
    </Carousel>
  );
};

export default About;
