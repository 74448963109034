import React from 'react';
import boxSizes from '../../constantData/boxSizes';
import { formatPriceForUI } from '../../utils';

const Cart = (props) => {
    return (
        <div style={{ display: props.cartDisplay }} className="cart">
            <i
                className="fas fa-times"
                onClick={props.cartDisplay == "block" ? props.closeCart : null}
            >
            </i>
            <h2>
                máš chuť na čokolásku?
                </h2>
            <h3>
                objednaj si Simply Chocolate už dnes!
                </h3>
            <h2 style={{ marginTop: "7%" }}>
                veľkosť
                </h2>
            <ul className="box-sizes">
                {boxSizes.map(({ size, price, productCount }) => {
                    const priceToShow = formatPriceForUI(price)
                    return (
                        <li key={size}>
                            <button onClick={props.handleSetThisProductQuantity}>
                                {productCount}ks - <strong>{size}</strong> - {priceToShow}
                            </button>
                        </li>
                    )}
                )}
            </ul>
            <span
                style={{
                    display: props.hintDisplay,
                    color: `red`,
                    fontFamily: `Social Gothic Demi-Bold`,
                    textTransform: `uppercase`
                }}
            >
                vyber si veľkosť boxu.
                </span>
            <button onClick={props.handleMixOwn}>
                namixujem si vlastný box
            </button><br />
            <button onClick={props.handleThisFlavour}>
                chcem túto príchuť
            </button>
        </div>
    )

}

export default Cart