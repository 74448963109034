import React from 'react';
import AboutPageDesktop from './AboutPageDesktop';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import {aboutPages} from "../../constantData/aboutPagesData";

const AboutDesktop = () => {

    return (
        <Carousel className="desktop-section" autoPlay={true} interval={5000} infiniteLoop>
            {aboutPages.map((page) =>
                    <AboutPageDesktop
                        bg={page.bg}
                        icon={page.icon}
                        heading={page.heading}
                        desc={page.desc}
                        key={page.heading}
                    />
            )}
        </Carousel>
    )

}

export default AboutDesktop
