import React, { useContext, useState } from "react";
import ReactCompareImage from "react-compare-image";
import { useHistory } from "react-router-dom";
import CartDesktop from "./CartDesktop";
import OpenProductNutrition from "../mutual/OpenProductNutrition";
import ProductNutritionDesktop from "./ProductNutritionDesktop";
import products from "../../constantData/products";
import { CartContext } from "../../CartContext";
import Typical from "react-typical";
import CompareImgHint from "./CompareImgHint";

const ProductDesktop = (props) => {
  const {
    boxQuantity,
    setBoxQuantity,
    setTotalBoxQuantity,
    setAndSaveToLocalStorage,
  } = useContext(CartContext);

  let history = useHistory();

  const [cartDisplay, setCartDisplay] = useState("none");
  const [boxSize, setBoxSize] = useState("unset");
  const [hintDisplay, setHintDisplay] = useState("none");
  const [nutritionDisplay, setNutritionDisplay] = useState("none");
  const [hoveredCTAButton, setHoveredCTAButton] = useState(false);

  const toggleCartDisplay = (event) => {
    event.preventDefault();

    if (cartDisplay === "block") {
      setCartDisplay("none");
      setHintDisplay("none");
    } else {
      setCartDisplay("block");
      setHintDisplay("none");
    }
  };

  const toggleNutritionDisplay = (event) => {
    event.preventDefault();

    if (nutritionDisplay === "block") {
      return setNutritionDisplay("none");
    } else {
      return setNutritionDisplay("block");
    }
  };

  const newBoxQuantity = (productsCount) => {
    let newBoxQuantity = [...boxQuantity].fill(0);
    const thisProductIndex = products.indexOf(
      products.find((product) => product.name === props.name)
    );

    newBoxQuantity[thisProductIndex] = productsCount;
    setAndSaveToLocalStorage(
      newBoxQuantity.reduce((a, b) => a + b, 0),
      setTotalBoxQuantity,
      "totalBoxQuantity"
    );

    return newBoxQuantity;
  };

  const handleSetThisProductQuantity = (event) => {
    let boxSizeSelected = event.target.children.item(0)
      ? event.target.children.item(0).textContent.trim()
      : event.target.textContent.trim();
    setBoxSize(boxSizeSelected);

    let productsCount;
    switch (boxSizeSelected) {
      case "S":
        productsCount = 6;
        break;
      case "M":
        productsCount = 12;
        break;
      case "L":
        productsCount = 24;
        break;
      case "XL":
        productsCount = 30;
        break;
    }
    setAndSaveToLocalStorage(
      () => newBoxQuantity(productsCount),
      setBoxQuantity,
      "boxQuantity"
    );
  };

  const handleThisFlavour = () => {
    if (boxSize === "unset") {
      setHintDisplay("inline");
    } else {
      history.push({
        pathname: "/cart-check",
        from: "/#products",
      });
    }
  };

  const handleMixOwn = () => {
    history.push({
      pathname: "/mixed-box",
      boxSize: boxSize,
      from: "/#products",
    });
  };

  const toggleHoveredCTAButton = () => {
    setHoveredCTAButton((prevValue) => {
      return !prevValue;
    });
  };

  return (
    <div style={{ position: `relative` }}>
      {!props.isSoldOut && (
        <button
          onMouseEnter={toggleHoveredCTAButton}
          onMouseLeave={toggleHoveredCTAButton}
          onClick={toggleCartDisplay}
          style={{
            color: hoveredCTAButton ? "#fff" : props.color,
            backgroundColor: hoveredCTAButton ? props.color : "",
          }}
          className="desktop-open-cart product-cta-animation"
        >
          chcem túto tyčinku!
        </button>
      )}
      <OpenProductNutrition
        handleClick={toggleNutritionDisplay}
        top="20vh"
        left="28vw"
        fontSize="1em"
      />
      <ReactCompareImage
        leftImage={`/img/desktop/${props.name.replace(/ /g, "")}.png`}
        leftImageLabel={
          <div
            onClick={toggleCartDisplay}
            className="desktop-product-shown-section"
          >
            <CompareImgHint />
            <h1 className="desktop-product-shown-heading desktop-heading">
              {props.name.split(" ")[0]}
              <br />
              {props.isHalfDiscounted ? props.name.split(" ")[1] : ""}
              {props.isHalfDiscounted && (
                <img
                  src="/img/mutual/sale.png"
                  alt=""
                  style={{
                    width: "0.65em",
                    position: "absolute",
                    bottom: "0em",
                    right: "-0.55em",
                  }}
                />
              )}
            </h1>
            {!props.isHalfDiscounted && (
              <>
                <br />
                <Typical
                  steps={["", 1400, props.name.split(" ")[1], 1200]}
                  loop={Infinity}
                  wrapper="h1"
                  className="desktop-product-shown-heading desktop-heading"
                />
              </>
            )}
            {props.isSoldOut ? (
              <div className="desktop-sold-out">vypredané</div>
            ) : (
              <div>
                <strong
                  className="desktop-price"
                  style={{
                    textDecoration: props.isHalfDiscounted
                      ? "line-through"
                      : "",
                  }}
                >
                  2,29 €
                </strong>
                {props.isHalfDiscounted && (
                  <strong className="desktop-new-price">1,25 €</strong>
                )}
              </div>
            )}
            <p className="desktop-product-shown-desc">
              {props.textFirst}
              <br />
              {props.textSecond}
              <br />
              {props.textThird}
            </p>
            <img
              src={`/img/desktop/${props.icons}-icons.png`}
              className="desktop-product-icons"
              alt={""}
            />
          </div>
        }
        rightImage={`/img/desktop/${props.name.replace(/ /g, "")}-white.png`}
        rightImageLabel={
          <div className="desktop-product-hidden-section">
            <h1
              style={{ color: props.color }}
              className="desktop-product-hidden-heading"
            >
              {props.name.split(" ")[0]}
              <br />
              {props.name.split(" ")[1]}
              <br />
              {props.isHalfDiscounted && (
                <img
                  src="/img/mutual/sale.png"
                  alt=""
                  style={{
                    width: "0.65em",
                    position: "absolute",
                    bottom: "0em",
                    right: "-0.2em",
                  }}
                />
              )}
            </h1>
            {props.isSoldOut ? (
              <div className="desktop-sold-out">vypredané</div>
            ) : (
              <div>
                <strong
                  style={{
                    color: props.color,
                    textDecoration: props.isHalfDiscounted
                      ? "line-through"
                      : "",
                  }}
                  className="desktop-price"
                >
                  2,29 €
                </strong>
                {props.isHalfDiscounted && (
                  <strong
                    style={{ bottom: "6em" }}
                    className="desktop-new-price"
                  >
                    1,25 €
                  </strong>
                )}
              </div>
            )}
            <p className="desktop-product-hidden-desc">{props.desc}</p>
          </div>
        }
        handle={<React.Fragment />}
      />
      <CartDesktop
        cartDisplay={cartDisplay}
        toggleCartDisplay={toggleCartDisplay}
        handleSetThisProductQuantity={handleSetThisProductQuantity}
        hintDisplay={hintDisplay}
        handleThisFlavour={handleThisFlavour}
        handleMixOwn={handleMixOwn}
      />
      <ProductNutritionDesktop
        nutritionDisplay={nutritionDisplay}
        toggleNutritionDisplay={toggleNutritionDisplay}
        nutrition={props.nutrition}
        nutritionDesc={props.nutritionDesc}
      />
    </div>
  );
};

export default ProductDesktop;
