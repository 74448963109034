import React, {useContext} from "react";
import { CartContext } from "../../CartContext";
import productsSecond from '../../constantData/productsSecond';

const ProductsSecondCount = () => {
    const {productsSecondQuantity, setProductsSecondQuantity, setAndSaveToLocalStorage} = useContext(CartContext);

    const handleChangeQuantity = (event, thisProductQuantityIndex) => {
        const isIncrement = event.target.textContent === "+";
        const amountToChangeBy = isIncrement ? 1 : -1;

        setAndSaveToLocalStorage(() => {
            let newProductsSecondQuantity = [...productsSecondQuantity];
            if (newProductsSecondQuantity[thisProductQuantityIndex] !== 0 || isIncrement) {
                newProductsSecondQuantity[thisProductQuantityIndex] = newProductsSecondQuantity[thisProductQuantityIndex] + amountToChangeBy;
            }
            return newProductsSecondQuantity;
        }, setProductsSecondQuantity, "productsSecondQuantity");
    }

    return (
        <div className="count">
            <div className="product-quantities">
                <strong>množstvo</strong>
                {
                    productsSecondQuantity.map((quantity, index) => {
                        const thisProduct = productsSecond[index];

                        if (!thisProduct.soldOut) return (
                            <div className="product-quantity">
                                <img src={`/img/mutual/${thisProduct.name.replace(/\s+/g, '-')}.webp`} alt="" />
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }} key={index}>
                                    <button onClick={(event) => handleChangeQuantity(event, index)}>-</button>
                                    <span>{quantity}</span>
                                    <button onClick={(event) => handleChangeQuantity(event, index)}>+</button>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default ProductsSecondCount
