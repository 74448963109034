import React, {useState} from "react";
import ProductDesktop from "./ProductDesktop";
import { Carousel } from "react-responsive-carousel";
import { useLocation } from "react-router-dom";
import products from "../../constantData/products";
import {findProductBySearchQuery} from "../../utils/";
import ProductsNavigation from "./ProductsNavigation";

const ProductsDesktop = () => {
    const location = useLocation();
    const initialSelectedItem = findProductBySearchQuery(location);

    const [selectedItem, setSelectedItem] = useState();
    const [selectedInitialItem, setSelectedInitialItem] = useState(false);

    return (
        <div style={{ position: "relative"}}>
            <Carousel showIndicators={false} className="desktop-section" autoPlay={false} interval={7500} infiniteLoop selectedItem={selectedInitialItem ? selectedItem : initialSelectedItem}>
                {
                    products.map((product) =>
                        <ProductDesktop
                            name={product.name}
                            icons={product.icons}
                            desc={product.desktopDesc}
                            textFirst={product.textFirst}
                            textSecond={product.textSecond}
                            textThird={product.textThird}
                            nutritionDesc={product.nutritionDesc}
                            nutrition={product.nutrition}
                            color={product.color}
                            isHalfDiscounted={product.isHalfDiscounted}
                            isSoldOut={product.soldOut}
                            key={product.name}
                        />
                    )
                }
            </Carousel>
            <ProductsNavigation products={products} setSelectedItem={setSelectedItem} setSelectedInitialItem={setSelectedInitialItem} />
        </div>
    )

}

export default ProductsDesktop
