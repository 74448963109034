import React from 'react';
import flavors from '../../constantData/chocolateBoxFlavors';

const ProductNutritionDesktop = (props) => {

    const nutritionLabels = [
        "Energia",
        "Tuk",
        "- nasýtené kyseliny",
        "Sacharidy",
        "- cukry",
        "Bielkoviny",
        "Soľ"
    ];

    let tableRows = [];
    for (let i = 0; i < nutritionLabels.length; i++) {
        tableRows.push(
            <tr key={nutritionLabels[i]}>
                <th>{nutritionLabels[i]}</th><td>{props.nutrition[i]}</td>
            </tr>
        )
    }

    return (
        <div style={{ display: props.nutritionDisplay }} className="desktop-product-nutrition">
            <h3 className="weight">
                <strong>hmotnosť<br />tyčinky</strong><br />40g
            </h3>
            <i className="fas fa-times" onClick={props.toggleNutritionDisplay}></i>
            {props.isChocolateBox && 
                <div className="flavors">
                    <h2 className="heading">príchute</h2>
                    <ul>
                        {flavors.map((flavor) => {
                            return (
                                <li>{flavor}</li>
                            )
                        })}
                    </ul>
                </div>
            }
            <div className="description">
                <h2 className="heading">
                    zloženie
                </h2>
                <p>
                    {props.nutritionDesc}
                </p>
            </div>
            <div className="nutrition-table">
                <h2 className="heading">
                    energetická<br />hodnota<br />/ 100 G
            </h2>
                <table>
                    {tableRows}
                </table>
            </div>
        </div>
    )

}

export default ProductNutritionDesktop