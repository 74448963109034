import React from "react";
import ReactCompareImage from "react-compare-image";
import Typical from "react-typical";
import Burger from "@animated-burgers/burger-squeeze";
import Menu from "../mutual/Menu";
import Social from "../mutual/Social";
import { introDesc } from "../../constantData/introDesc";

const IntroDesktop = () => {
  const [burgerState, setBurgerState] = React.useState(false);

  const toggleBurger = () => {
    setBurgerState((prevBurgerState) => !prevBurgerState);
  };

  return (
    <>
      <div className="desktop-section" style={{ position: "relative" }}>
        <Burger
          isOpen={burgerState}
          onClick={toggleBurger}
          style={{
            position: "absolute",
            zIndex: "2",
            margin: "3em",
          }}
        />
        <Menu
          display={burgerState ? "block" : "none"}
          burgerState={burgerState}
          toggleBurger={toggleBurger}
        />
        <Social />
        <i className="fas fa-arrow-down" />
        <ReactCompareImage
          leftImage="/img/desktop/intro-left-image.jpg"
          leftImageLabel={
            <>
              <div className="desktop-intro-shown">
                <h1
                  className="desktop-intro-first-heading desktop-heading"
                  style={{ marginBottom: "0" }}
                >
                  yes
                  <br />
                  you can
                </h1>
                <Typical
                  steps={["", 1400, "BUY LOVE", 1200]}
                  loop={Infinity}
                  wrapper="h1"
                  className="desktop-intro-first-heading desktop-heading"
                />
                <img
                  className="desktop-intro-icons"
                  src="/img/desktop/icons-white.png"
                  alt={""}
                />
              </div>
            </>
          }
          rightImage="/img/desktop/intro-right-image.jpg"
          rightImageLabel={
            <>
              <div className="desktop-intro-hidden-section">
                <div className="desktop-intro-hidden-text">
                  <h1 className="desktop-intro-hidden-heading desktop-heading">
                    o nás
                  </h1>
                  <img
                    src="/img/desktop/logo-black.png"
                    className="desktop-logo"
                    alt={""}
                  />
                  <div className="desktop-intro-company-desc">
                    {introDesc.map((blockOfText) => {
                      return <p key={blockOfText}>{blockOfText}</p>;
                    })}
                  </div>
                </div>
                <img
                  className="desktop-intro-icons"
                  src="img/desktop/icons-black.png"
                />
              </div>
            </>
          }
          handle={
            <div
              style={{
                color: "white",
                position: "relative",
                top: "12em",
                fontSize: "1.75em",
              }}
            >
              <i
                style={{ position: "absolute", right: "0.5em" }}
                className="fas fa-chevron-left intro-left-arrow"
              />
              <i
                style={{ position: "absolute", left: "0.5em" }}
                className="fas fa-chevron-right intro-right-arrow"
              />
            </div>
          }
          sliderLineWidth={4}
        />
      </div>
      <div
        className="desktop-section"
        style={{
          backgroundImage: `url('/img/desktop/in-products-background.webp')`,
          backgroundSize: "cover",
        }}
      >
        <div
          className="desktop-where-to-find-us"
          style={{
            backgroundColor: "transparent",
            position: "relative",
            width: "100vw",
            height: "100vh",
          }}
        >
          <h1 className="desktop-heading">
            šampióni
            <br />
            <Typical
              steps={["", 1400, "CHUTÍ", 1200]}
              loop={Infinity}
              wrapper="div"
              className="desktop-heading"
            />
          </h1>
          <h2 style={{ textAlign: "right" }} className="desktop-heading">
            kde
            <br /> nás
            <br />
            <Typical
              steps={["", 3000, "nájdete?", 500]}
              wrapper="div"
              loop={Infinity}
            />
          </h2>
          <div className="desktop-logos">
            <img src="/img/mutual/terno.webp" alt={""} />
            <img src="/img/mutual/kraj.webp" alt={""} />
            <img
              src="/img/mutual/shell.webp"
              style={{ marginTop: "0.75%" }}
              alt={""}
            />
          </div>
          <div className="desktop-bars">
            <img src="/img/desktop/bar-1.png" alt={""} />
            <img src="/img/desktop/bar-2.png" alt={""} />
            <img src="/img/desktop/bar-3.png" alt={""} />
          </div>
        </div>
      </div>
    </>
  );
};

export default IntroDesktop;
