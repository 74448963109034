import products from "../constantData/products";
import productsSecond from "../constantData/productsSecond";

export const findProductBySearchQuery = (location, setSelectedInitialItem) => {
  const query = location.pathname.replace("/", "");

  if (query.length) {
    const productName = query.replaceAll("-", " ");
    const productsElementId = products.some(
      (product) => product.name === productName
    )
      ? "#products"
      : productsSecond.some((product) => product.name === productName)
      ? "#products-second"
      : "";

    if (location.hash === productsElementId) {
      if (productsElementId === "#products") {
        const foundProduct = products.find(
          (product) => product.name === productName
        );
        return products.indexOf(foundProduct)
          ? products.indexOf(foundProduct)
          : 0;
      } else if (productsElementId === "#products-second") {
        const foundProduct = productsSecond.find(
          (product) => product.name === productName
        );
        return productsSecond.indexOf(foundProduct)
          ? productsSecond.indexOf(foundProduct)
          : 0;
      }
    } else {
      return window.location.replace(
        window.location.origin + "/" + query + productsElementId
      );
    }
  }
};

export const toCamelCase = (string) =>
  string.replace(/\s+(.)/g, (match, group) => group.toUpperCase());

export const calcOneProductPriceForBox = (productCountInBox, boxPrice) => {
  return boxPrice / productCountInBox;
};

export const isProductDiscountedByHalf = (
  productIndex,
  isFromProductsSecond
) => {
  if (isFromProductsSecond) {
    return productsSecond[productIndex].halfDiscount;
  } else {
    return products[productIndex].halfDiscount;
  }
};

export const calcHalfPriceOfProduct = (oneProductPrice) => {
  return oneProductPrice / 2 > 125 ? oneProductPrice / 2 : 125;
};

export const totalPriceToString = (price) => {
  return (price / 100).toFixed(2).replace(".", ",");
};

export const orderNumberStringToBesteronVs = (orderNumberString) => {
  return parseInt(orderNumberString.replace(/-/g, "").substring(0, 9));
};

export const formatPriceForUI = (price) => new Intl.NumberFormat("sk-SK", { style: "currency", currency: "EUR" }).format(price / 100)