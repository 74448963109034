import React from 'react';

const ZasadyOchranyOsobnychUdajov = () => {


    return (
        <object 
            data="/pdf/zasady-ochrany-osobnych-udajov.pdf" 
            type="application/pdf"
            width="100%"
            height="100%"
        >

        </object>
    )
}

export default ZasadyOchranyOsobnychUdajov