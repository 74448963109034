import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";

import { UPDATE_ORDER } from "../../../config/endpoints";
const URL = UPDATE_ORDER;

const AfterPayment = () => {

    const history = useHistory();
    const location = useLocation();

    const [orderPaid, setOrderPaid] = useState(false); 

    const paymentDetails = queryString.parse(location.search);
    const paymentId = paymentDetails.VS;

    useEffect(() => {
        if (paymentDetails.RESULT === "OK") {
            if (paymentId) {
                (async () => {
                    const res = await window.fetch(URL, {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify(paymentDetails)
                    })
                    const json = await res.json()

                    if (json.VALID) {
                        setOrderPaid(true);
                    }
                })()
            }
        }

    }, []);

    return (
        <div className="after-payment">
            <h1>Ďakujeme za Vašu objednávku!</h1>
            <h2>
                {orderPaid || Object.keys(paymentDetails).length === 0 ? "Skontrolujte si prosím Váš email." : "Uhraďte prosím platbu."}
            </h2>
            <a onClick={() => history.push({ pathname: "/" })}>späť domov</a>
        </div>
    )
}

export default AfterPayment