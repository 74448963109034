import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "@animated-burgers/burger-squeeze/dist/styles.css";
import "./css/mobileApp.css";
import "./css/desktopApp.css";
import "./css/menu.css";
import "./css/social.css";
import "./css/buyerInfo.css";
import "./css/mixedBox.css";
import "./css/chocolateBox.css";
import "./css/cartCheck.css";
import "./css/shippingAndPaymentMethod.css";
import "./css/checkBuyerInfo.css";
import "./css/afterPayment.css";
import { isMobile } from "react-device-detect";
import Desktop from "./components/Desktop";
import Mobile from "./components/Mobile";
import ObchodnePodmienky from "./components/mutual/ObchodnePodmienky";
import ZasadyOchranyOsobnychUdajov from "./components/mutual/ZasadyOchranyOsobnychUdajov";
import OdstupenieOdKupnejZmluvy from "./components/mutual/OdstupenieOdKupnejZmluvy";
import BuyerInformation from "./components/mutual/checkout/BuyerInformation";
import MixedBox from "./components/mutual/MixedBox";
import ChocolateBox from "./components/mutual/ChocolateBox";
import CartCheck from "./components/mutual/checkout/CartCheck";
import ShippingAndPaymentMethod from "./components/mutual/checkout/ShippingAndPaymentMethod";
import CheckBuyerInformation from "./components/mutual/checkout/CheckBuyerInformation";
import AfterPayment from "./components/mutual/checkout/AfterPayment";
import products from "./constantData/products";
import productsSecond from "./constantData/productsSecond";
import boxSizes from "./constantData/boxSizes";
import { CartContext } from "./CartContext";
import {
  calcHalfPriceOfProduct,
  calcOneProductPriceForBox,
  isProductDiscountedByHalf,
} from "./utils";

const App = () => {
  const [boxQuantity, setBoxQuantity] = useState(products.map(() => 0));
  const [totalBoxQuantity, setTotalBoxQuantity] = useState(0);
  const [productsSecondQuantity, setProductsSecondQuantity] = useState([
    ...productsSecond.map(() => 0),
  ]);
  const [totalPrice, setTotalPrice] = useState(0.0);
  const [enteredCartAndLeft, setEnteredCartAndLeft] = useState(false);

  const setAndSaveToLocalStorage = (newValue, setFuncForValue, key) => {
    setFuncForValue(newValue);

    const newValueSerialized = JSON.stringify(
      typeof newValue === "function" ? newValue() : newValue
    );
    localStorage.setItem(key, newValueSerialized);

    if (newValue) {
      localStorage.setItem("lastUpdated", Date.now());
    }
  };

  const calculateProductsPrice = () => {
    const thisBoxSize = boxSizes.find(
      (boxSize) => boxSize.productCount === totalBoxQuantity
    );
    const oneProductPrice = calcOneProductPriceForBox(
      totalBoxQuantity,
      thisBoxSize.price
    );

    let totalProductsPrice = 0;
    for (let i = 0; i < boxQuantity.length; i++) {
      if (isProductDiscountedByHalf(i, false)) {
        totalProductsPrice +=
          boxQuantity[i] * calcHalfPriceOfProduct(oneProductPrice);
      } else {
        totalProductsPrice += boxQuantity[i] * oneProductPrice;
      }
    }

    return totalProductsPrice;
  };

  const calculateProductsSecondPrice = () => {
    let totalProductsSecondPrice = 0;

    for (let i = 0; i < productsSecondQuantity.length; i++) {
      const thisProductPrice = isProductDiscountedByHalf(i, true)
        ? productsSecondQuantity[i] *
          calcHalfPriceOfProduct(productsSecond[i].price)
        : productsSecondQuantity[i] * productsSecond[i].price;
      totalProductsSecondPrice += thisProductPrice;
    }

    return totalProductsSecondPrice;
  };

  const calculateTotalPrice = () => {
    let newTotalPrice = 0;

    if (totalBoxQuantity) {
      newTotalPrice += calculateProductsPrice();
    }

    newTotalPrice += calculateProductsSecondPrice();

    return newTotalPrice;
  };

  const toGetFromLocalStorage = [
    { key: "boxQuantity", setFunc: setBoxQuantity, state: boxQuantity },
    {
      key: "totalBoxQuantity",
      setFunc: setTotalBoxQuantity,
      state: totalBoxQuantity,
    },
    {
      key: "productsSecondQuantity",
      setFunc: setProductsSecondQuantity,
      state: productsSecondQuantity,
    },
    { key: "totalPrice", setFunc: setTotalPrice, state: totalPrice },
  ];

  useEffect(() => {
    const clearedAfterFix = localStorage.getItem("clearedAfterFix")
      ? JSON.parse(localStorage.getItem("clearedAfterFix"))
      : false;
    const lastUpdated = localStorage.getItem("lastUpdated")
      ? JSON.parse(localStorage.getItem("lastUpdated"))
      : false;
    const day = 86400000;
    const isNotOlderThanThreeDays = lastUpdated
      ? Date.now() - lastUpdated < 3 * day
      : false;

    if (clearedAfterFix && isNotOlderThanThreeDays) {
      toGetFromLocalStorage.forEach((item) => {
        const itemValueFromLocalStorage = localStorage.getItem(item.key);
        if (itemValueFromLocalStorage) {
          const valueDeserialized = JSON.parse(itemValueFromLocalStorage);
          item.setFunc(valueDeserialized);
        }
      });
    } else {
      localStorage.clear();
      localStorage.setItem("clearedAfterFix", JSON.stringify(true));

      toGetFromLocalStorage.forEach((item) => {
        localStorage.setItem(item.key, JSON.stringify(item.state));
      });
    }
  }, []);

  useEffect(() => {
    const newTotalPrice = calculateTotalPrice();
    setAndSaveToLocalStorage(newTotalPrice, setTotalPrice, "totalPrice");
  }, [boxQuantity, productsSecondQuantity]);

  useEffect(() => {
    if (totalPrice > 0) {
      setEnteredCartAndLeft(true);
    }
  }, [totalPrice]);

  const cartContext = {
    boxQuantity,
    setBoxQuantity,
    totalBoxQuantity,
    setTotalBoxQuantity,
    productsSecondQuantity,
    setProductsSecondQuantity,
    totalPrice,
    setTotalPrice,
    setAndSaveToLocalStorage,
    enteredCartAndLeft,
    setEnteredCartAndLeft,
  };

  return (
    <CartContext.Provider value={cartContext}>
      <Switch>
        {isMobile ? (
          <Route exact path="/" component={Mobile} />
        ) : (
          <Route exact path="/" component={Desktop} />
        )}
        <Route path="/mixed-box" component={MixedBox} />
        {!productsSecond.every((product) => product.soldOut) && (
          <Route path="/chocolate-box" component={ChocolateBox} />
        )}
        <Route path="/cart-check" component={CartCheck} />
        <Route path="/buyer-info" component={BuyerInformation} />
        <Route
          path="/shipping-and-payment-method"
          component={ShippingAndPaymentMethod}
        />
        <Route path="/buyer-info-check" component={CheckBuyerInformation} />
        <Route path="/obchodne-podmienky" component={ObchodnePodmienky} />
        <Route
          path="/zasady-ochrany-osobnych-udajov"
          component={ZasadyOchranyOsobnychUdajov}
        />
        <Route
          path="/odstupenie-od-kupnej-zmluvy"
          component={OdstupenieOdKupnejZmluvy}
        />
        <Route path="/paid" component={AfterPayment} />
        {isMobile ? (
          <Route component={Mobile} />
        ) : (
          <Route component={Desktop} />
        )}
      </Switch>
    </CartContext.Provider>
  );
};

export default App;
