import React from 'react';
import boxSizes from '../../constantData/boxSizes';
import { formatPriceForUI } from '../../utils';

const CartDesktop = (props) => {
    return (
        <div style={{ display: props.cartDisplay }} className="desktop-cart">
            <i
                className="fas fa-times"
                onClick={props.toggleCartDisplay}
            >
            </i>
            <h2>
                máš chuť na čokolásku?
                </h2>
            <h3>
                objednaj si Simply Chocolate už dnes!
                </h3>
            <h2>
                veľkosť
                </h2>
            <ul className="desktop-box-sizes">
                {boxSizes.map(({ price, productCount, size }) => {
                    const priceToShow = formatPriceForUI(price)
                    return (
                        <li key={size}>
                            <button style={{ cursor: 'pointer' }} onClick={props.handleSetThisProductQuantity}>
                                <div><strong>{size} </strong></div>- {productCount}ks - {priceToShow}
                            </button>
                        </li>
                        )
                    }
                )}
            </ul>
            <span
                style={{
                    display: props.hintDisplay,
                    color: `red`,
                    fontFamily: `Social Gothic Demi-Bold`,
                    textTransform: `uppercase`
                }}
            >
                vyber si veľkosť.
                </span>
            <button style={{ color: `#fff` }} onClick={props.handleMixOwn}>
                namixujem si vlastný box    
            </button>
            <br />
            <button onClick={props.handleThisFlavour}>chcem túto príchuť</button>
        </div>
    )

}

export default CartDesktop