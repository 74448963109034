import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import OpenProductNutrition from "../mutual/OpenProductNutrition";
import Cart from "./Cart";
import ProductNutrition from "./ProductNutrition";
import products from "../../constantData/products";
import { CartContext } from "../../CartContext";

const Product = (props) => {
  const {
    boxQuantity,
    setBoxQuantity,
    setTotalBoxQuantity,
    setAndSaveToLocalStorage,
  } = useContext(CartContext);

  let history = useHistory();

  const [cartDisplay, setCartDisplay] = React.useState("none");
  const [boxSize, setBoxSize] = React.useState("unset");
  const [hintDisplay, setHintDisplay] = React.useState("none");
  const [nutritionDisplay, setNutritionDisplay] = React.useState("none");

  const openCart = () => {
    setCartDisplay("block");
    props.onOpenCart();
  };

  const closeCart = () => {
    setCartDisplay("none");
  };

  const toggleNutritionDisplay = () => {
    if (nutritionDisplay === "none") {
      setNutritionDisplay("block");
    } else {
      setNutritionDisplay("none");
    }
  };

  const newBoxQuantity = (productsCount) => {
    let newBoxQuantity = [...boxQuantity].fill(0);
    const thisProductIndex = products.indexOf(
      products.find((product) => product.name === props.name)
    );

    newBoxQuantity[thisProductIndex] = productsCount;
    setAndSaveToLocalStorage(
      newBoxQuantity.reduce((a, b) => a + b, 0),
      setTotalBoxQuantity,
      "totalBoxQuantity"
    );

    return newBoxQuantity;
  };

  const addBorderToSelectedSize = (clickedSizeElement) => {
    const boxSizes = clickedSizeElement.parentElement.parentElement.children;
    for (let item of boxSizes) {
      item.children[0].classList.remove("active");
    }

    clickedSizeElement.classList.add("active");
  };

  const handleSetThisProductQuantity = (event) => {
    const clickedSizeElement = event.target;
    let boxSizeSelected = clickedSizeElement.children.item(0)
      ? clickedSizeElement.children.item(0).textContent.trim()
      : clickedSizeElement.textContent.trim();
    setBoxSize(boxSizeSelected);

    let productsCount;
    switch (boxSizeSelected) {
      case "S":
        productsCount = 6;
        break;
      case "M":
        productsCount = 12;
        break;
      case "L":
        productsCount = 24;
        break;
      case "XL":
        productsCount = 30;
        break;
    }
    setAndSaveToLocalStorage(
      newBoxQuantity(productsCount),
      setBoxQuantity,
      "boxQuantity"
    );

    addBorderToSelectedSize(clickedSizeElement);
  };

  const handleThisFlavour = () => {
    if (boxSize === "unset") {
      setHintDisplay("block");
    } else {
      history.push({
        pathname: "/cart-check",
        from: "/#products",
      });
    }
  };

  const handleMixOwn = () => {
    history.push({
      pathname: "/mixed-box",
      from: "/#products",
      boxSize: boxSize,
    });
  };

  return (
    <>
      <div
        style={{
          background: `url(img/mobile/${props.name.replace(
            " ",
            ""
          )}-bg.webp) no-repeat`,
          backgroundPosition: "50% 40%",
          backgroundSize: "cover",
        }}
        className="section product-section"
      >
        <OpenProductNutrition
          handleClick={toggleNutritionDisplay}
          top="35%"
          left="65%"
          fontSize="0.9em"
        />
        <h1 style={{ color: props.color }} className="product-heading">
          {props.name.split(" ")[0]}
          <br />
          {props.name.split(" ")[1]}
          {props.isHalfDiscounted && (
            <img
              src="/img/mutual/sale.png"
              alt=""
              style={{
                width: "0.65em",
                position: "absolute",
                bottom: "0em",
                right: "-0.55em",
              }}
            />
          )}
        </h1>
        {props.isSoldOut ? (
          <div className="price">vypredané</div>
        ) : (
          <div className="price">
            <strong
              style={{
                color: props.color,
                textDecoration: props.isHalfDiscounted ? "line-through" : "",
              }}
            >
              2,29€
            </strong>
            {props.isHalfDiscounted && (
              <strong className="new-price">1,25 €</strong>
            )}
          </div>
        )}
        <img
          src={`/img/mobile/${props.name.replace(" ", "")}-bar.webp`}
          className="product"
          alt={""}
        />
        {!props.isSoldOut && (
          <div onClick={openCart} className="open-cart product-cta-animation">
            <button style={{ color: props.color }}>Chcem túto tyčinku</button>
          </div>
        )}
        <p className="product-desc">
          {props.textFirst}
          <br />
          {props.textSecond}
          <br />
          {props.textThird}
        </p>
        <img
          className="product-icons"
          src={`/img/mobile/mobile-${props.icons}-icons.webp`}
          alt={""}
        />
        <Cart
          cartDisplay={cartDisplay}
          closeCart={closeCart}
          handleSetThisProductQuantity={handleSetThisProductQuantity}
          hintDisplay={hintDisplay}
          handleThisFlavour={handleThisFlavour}
          handleMixOwn={handleMixOwn}
        />
      </div>
      <ProductNutrition
        nutritionDisplay={nutritionDisplay}
        toggleNutritionDisplay={toggleNutritionDisplay}
        nutritionDesc={props.nutritionDesc}
        nutrition={props.nutrition}
      />
    </>
  );
};

export default Product;
