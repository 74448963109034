import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import Burger from "@animated-burgers/burger-squeeze";
import Typical from "react-typical";
import Menu from "../mutual/Menu";
import { introDesc } from "../../constantData/introDesc";

const Intro = () => {
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [burgerState, setBurgerState] = React.useState(false);

  const nextSlide = () => {
    if (currentSlide < 4) {
      setCurrentSlide((prevCurrentSlide) => prevCurrentSlide + 1);
    }
  };

  const prevSlide = () => {
    if (currentSlide > 0) {
      setCurrentSlide((prevCurrentSlide) => prevCurrentSlide - 1);
    }
  };

  const toggleBurger = () => {
    setBurgerState((prevBurgerState) => !prevBurgerState);
  };

  return (
    <>
      <Burger
        onClick={toggleBurger}
        style={{
          margin: "3em",
          zIndex: "3",
        }}
      />
      <Menu
        display={burgerState ? "block" : "none"}
        burgerState={burgerState}
        toggleBurger={toggleBurger}
      />
      <Carousel
        autoPlay={false}
        selectedItem={currentSlide}
        className="intro component"
      >
        <div
          style={{
            backgroundImage: `url("img/mobile/intro-first.webp")`,
            backgroundPosition: `bottom`,
            color: "#fff !important",
          }}
          className="section"
        >
          <i className="fas fa-arrow-down" />
          <h1 className="heading intro-first-heading">
            yes
            <br />
            you can
          </h1>
          <Typical
            steps={["", 1400, "BUY LOVE", 1200]}
            loop={Infinity}
            wrapper="h1"
            className="intro-first-heading heading"
          />
          <div className="slide-selectors">
            <button onClick={prevSlide}>
              <i className="fas fa-chevron-left" />
            </button>
            <button onClick={nextSlide}>
              <i className="fas fa-chevron-right" />
            </button>
          </div>
        </div>
        <div
          style={{
            backgroundImage: `url("img/mobile/intro-second.webp")`,
            backgroundPosition: "bottom center",
          }}
          className="section"
        >
          <div className="slide-selectors">
            <button onClick={prevSlide}>
              <i className="fas fa-chevron-left" />
            </button>
            <button onClick={nextSlide}>
              <i className="fas fa-chevron-right" />
            </button>
          </div>
        </div>
        <div style={{ backgroundColor: "#fff" }} className="section">
          <h1 className="heading intro-second-heading">o nás</h1>
          <img
            className="logo-first"
            src="/img/mobile/logoblackwithoutbreak.webp"
            alt={""}
          />
          <div className="company-desc">
            {introDesc.map((blockOfText) => {
              return (
                <>
                  <p>{blockOfText}</p>
                  <br />
                </>
              );
            })}
          </div>
          <img src="/img/mobile/icons-black.webp" alt={""} />
          <div className="slide-selectors">
            <button onClick={prevSlide}>
              <i className="fas fa-chevron-left" />
            </button>
            <button onClick={nextSlide}>
              <i className="fas fa-chevron-right" />
            </button>
          </div>
        </div>
        <div
          style={{ backgroundImage: `url("img/mobile/intro-forth.webp")` }}
          className="section"
        >
          <div className="slide-selectors">
            <button onClick={prevSlide}>
              <i className="fas fa-chevron-left" />
            </button>
            <button onClick={nextSlide}>
              <i className="fas fa-chevron-right" />
            </button>
          </div>
        </div>
        <div
          style={{ backgroundImage: `url("img/mobile/intro-fifth.webp")` }}
          className="section"
        >
          <img
            className="logo-vertical"
            src="img/mobile/logo-vertical.webp"
            alt={""}
          />
          <div className="slide-selectors">
            <button onClick={prevSlide}>
              <i className="fas fa-chevron-left" />
            </button>
            <button onClick={nextSlide}>
              <i className="fas fa-chevron-right" />
            </button>
          </div>
        </div>
      </Carousel>
      <div className="component" style={{ overflowY: "hidden" }}>
        <div
          className="section"
          style={{
            backgroundImage: 'url("/img/mobile/in-products-background.webp")',
          }}
        >
          <div
            style={{
              background: "transparent",
              width: "100vw",
              height: "100vh",
            }}
            className="where-to-find-us"
          >
            <h1 className="heading">šampióni</h1>
            <Typical
              steps={["", 1400, "CHUTÍ", 1200]}
              loop={Infinity}
              wrapper="h1"
              className="heading"
            />
            <div className="bars">
              <img src="/img/mutual/bars.png" alt={""} />
            </div>
            <h2 className="heading">
              nájdete
              <br />
              nás
            </h2>
            <div className="logo">
              <img src="/img/mutual/terno.webp" alt={""} />
            </div>
            <div className="logos">
              <img src="/img/mutual/kraj.webp" alt={""} />
              <img src="/img/mutual/shell.webp" alt={""} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Intro;
