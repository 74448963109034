import React from "react";

const OpenProductNutrition = (props) => {
  return (
    <button
      style={{
        backgroundImage: `url("./img/mutual/nutrition-button.webp")`,
        position: props.position ?? "absolute",
        top: props.top,
        left: props.left,
        fontSize: props.fontSize,
      }}
      className="open-product-nutrition"
      onClick={props.handleClick}
    >
      zloženie
    </button>
  );
};

export default OpenProductNutrition;
