import React from 'react';

const OdstupenieOdKupnejZmluvy = () => {


    return (
        <object 
            data="/pdf/odstupenie-od-kupnej-zmluvy.pdf" 
            type="application/pdf"
            width="100%"
            height="100%"
        >
        </object>
    )
}

export default OdstupenieOdKupnejZmluvy