import Typical from "react-typical";

const CompareImgHint = () => {
  return (
    <div className="hint">
      <img src="/img/desktop/hint.png" alt="" />
      <Typical
        steps={["", 3000, "rozbaľ ma!", 500]}
        wrapper="h3"
        loop={Infinity}
      />
    </div>
  );
};

export default CompareImgHint;
