const productsSecond = [
  {
    name: "yes you can buy love",
    firstLine: "yes you ",
    secondLine: "can buy love",
    color: "#82bbbd",
    price: 1400,
    pieces: 24,
    priceColor: "#536d71",
    nutrition: [
      "2311 kJ/552 kCal",
      "36 g",
      "22 g",
      "46 g",
      "44 g",
      "8,4 g",
      "0,26 g",
    ],
    nutritionDesc:
      "Kakaová hmota, cukor, kakaové maslo, celé MLIEKO v prášku, MANDLE, sušený kokos, karamelové chrumky, SÓJOVÁ bielkovina, emulgátor SÓJOVÝ lecitín, prírodná vanilková príchuť, sladké drievko, maliny (sušené mrazom), nerafinovaná morská soľ, soľ, citrónový olej, kávové zrná.",
  },
  {
    name: "instead of flowers",
    firstLine: "instead ",
    secondLine: "of flowers",
    color: "#ef8599",
    price: 840,
    pieces: 12,
    priceColor: "#803547",
    nutrition: [
      "2123 kJ/507 kCal",
      "32 g",
      "19 g",
      "46 g",
      "44 g",
      "8,2 g",
      "0,24 g",
    ],
    nutritionDesc:
      "Kakaová hmota, cukor, kakaové maslo, celé MLIEKO v prášku, MANDLE, sušený kokos, karamelové chrumky, SÓJOVÁ bielkovina, emulgátor SÓJOVÝ lecitín, prírodná vanilková príchuť, sladké drievko, maliny (sušené mrazom), nerafinovaná morská soľ, soľ, citrónový olej, kávové zrná.",
  },
  {
    name: "sharing is caring",
    firstLine: "sharing ",
    secondLine: "is caring",
    color: "#2a5284",
    price: 1200,
    pieces: 12,
    priceColor: "#071f3e",
    nutrition: [
      "2123 kJ/507 kCal",
      "32 g",
      "19 g",
      "46 g",
      "44 g",
      "8,2 g",
      "0,24 g",
    ],
    nutritionDesc:
      "Kakaová hmota, cukor, kakaové maslo, celé MLIEKO v prášku, MANDLE, sušený kokos, karamelové chrumky, SÓJOVÁ bielkovina, emulgátor SÓJOVÝ lecitín, prírodná vanilková príchuť, sladké drievko, maliny (sušené mrazom), nerafinovaná morská soľ, soľ, citrónový olej, kávové zrná.",
    soldOut: true,
  },
];

export default productsSecond;
