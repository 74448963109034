import React, {useContext} from "react";
import { useHistory } from "react-router-dom";
import { CartContext } from "../../../CartContext";

const CartIcon = () => {
    const {enteredCartAndLeft} = useContext(CartContext);
    
    const history = useHistory();

    const handleGoToCart = () => {
        history.push({
            pathname: '/mixed-box',
        });
    }

    return (
        <div className="cart-icon" onClick={handleGoToCart}>
            {enteredCartAndLeft ? <div className="cart-entered-indicator">1</div> : null}
            <i className="fas fa-shopping-cart" />
        </div>
    )
}

export default CartIcon
