import React, {useState} from "react";
import { Carousel } from 'react-responsive-carousel';
import ProductSecond from "./ProductSecond";
import productsSecond from "../../constantData/productsSecond";
import {useLocation} from "react-router-dom";
import {findProductBySearchQuery} from "../../utils/";
import {isMobile} from "react-device-detect";
import ProductsNavigation from "../desktop/ProductsNavigation";

const ProductsSecond = () => {
    const location = useLocation();
    const initialSelectedItem = findProductBySearchQuery(location);

    const [selectedItem, setSelectedItem] = useState(initialSelectedItem === -1 ? 0 : initialSelectedItem);
    const [selectedInitialItem, setSelectedInitialItem] = useState(false);

    return (
        <div className="desktop-section" style={{ position: "relative", zIndex: 2 }}>
            <Carousel showIndicators={false} selectedItem={selectedInitialItem ? selectedItem : initialSelectedItem}>
                {
                    productsSecond.map(product =>
                        <ProductSecond
                            name={product.name}
                            firstLine={product.firstLine}
                            secondLine={product.secondLine}
                            color={product.color}
                            price={product.price}
                            pieces={product.pieces}
                            priceColor={product.priceColor}
                            nutrition={product.nutrition}
                            nutritionDesc={product.nutritionDesc}
                            isHalfDiscounted={product.halfDiscount}
                            isSoldOut={product.soldOut}
                            key={product.firstLine}
                        />
                    )
                }
            </Carousel>
            {isMobile === false && <ProductsNavigation products={productsSecond} setSelectedItem={setSelectedItem} setSelectedInitialItem={setSelectedInitialItem} />}
        </div>
    )
}

export default ProductsSecond
