import React from "react";

const ProductsNavigation = (props) => {
    return <div className="desktop-custom-products-nav">
        {props.products.map((product, index) => {
            const imgName = product.name.replaceAll(" ", "-");
            return (
                <div
                    className="nav-item"
                    onClick={() => props.setSelectedItem(() => {
                        props.setSelectedInitialItem(true);
                        return index;
                    })}
                    key={product.name}
                >
                    <img src={`/img/desktop/nav/${imgName}.png`} alt={""} />
               </div>
            )}
        )}
    </div>
}

export default ProductsNavigation
