export const aboutPages = [

    {
        bg: "people",
        icon: "cocoahorizons",
        heading: "we care",
        desc: "Čokoláda, ktorú používame, pochádza z kakaa získavaného etickým a ekologickým poľnohospodárstvom."
    },

    {
        bg: "seeds",
        icon: "earth",
        heading: "all natural",
        desc: "Pokiaľ ide o naše ingrediencie, sme veľmi vyberaví. Využívame iba to najlepšie, čo príroda ponúka. Chuť a kvalita sú pre nás najdôležitejšie."
    },

    {
        bg: "chocolates",
        icon: "noartificials",
        heading: "no artificials",
        desc: "Využívame pomerne veľa času hľadaním skvelých ingrediencií a zaujímavých chutí. Naša čokoláda neobsahuje žiadne umelé farbivá a konzervanty."
    },

    {
        bg: "running",
        icon: "protein",
        heading: "high on protein",
        desc: "Naše proteínové tyčinky nie sú suché a ani bez chuti. Stavili sme na kvalitu, ale nezabudli sme ponechať aj vynikajúcu chuť čokolády a starostlivo vybraných surovín."
    },

    {
        bg: "bars",
        icon: "glutenfree",
        heading: "gluten free",
        desc: "Myslíme na všetkých milovníkov čokolády, a preto je väčšina z našich tyčiniek vhodná aj pre ľudí s alergiou na lepok."
    },

    {
        bg: "solarpanel",
        icon: "sun",
        heading: "solar",
        desc: "Vďaka vlastnému systému solárnych panelov, sme 100% energeticky sebestační a čokoládu vyrábame s nulovými emisiami CO2."
    }

]
