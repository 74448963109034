import React, {useState} from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import Product from './Product';
import { useLocation } from "react-router-dom";
import products from "../../constantData/products";
import {findProductBySearchQuery} from "../../utils";

const Products = () => {
    const location = useLocation();
    const selectedProductIndex = findProductBySearchQuery(location)

    const [autoPlay, setAutoPlay] = useState(true);

    const turnOffAutoPlay = () => {
        setAutoPlay(false);
    }

    return (
        <Carousel autoPlay={false} interval={1000} infiniteLoop selectedItem={selectedProductIndex} className="products component">

            {
                products.map(product =>
                    <Product
                        name={product.name}
                        icons={product.icons}
                        textFirst={product.textFirst}
                        textSecond={product.textSecond}
                        textThird={product.textThird}
                        nutritionDesc={product.nutritionDesc}
                        nutrition={product.nutrition}
                        color={product.color}
                        onOpenCart={turnOffAutoPlay}
                        isHalfDiscounted={product.halfDiscount}
                        isSoldOut={product.soldOut}
                        key={product.name}
                    />
                )
            }

        </Carousel>
    )

}

export default Products
