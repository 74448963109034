import React, {useState, useRef, useContext, useEffect} from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import MixedBoxProduct from './MixedBoxProduct';
import MixedBoxBoxSize from './MixedBoxBoxSize';
import products from '../../constantData/products';
import boxSizes from '../../constantData/boxSizes';
import { CartContext } from '../../CartContext';
import { totalPriceToString } from '../../utils';
import productsSecond from '../../constantData/productsSecond';

const MixedBox = () => {
    const {boxQuantity, setBoxQuantity, totalBoxQuantity, setTotalBoxQuantity, setAndSaveToLocalStorage, totalPrice} = useContext(CartContext);

    const history = useHistory();
    const location = useLocation();

    const [boxSize, setBoxSize] = useState('unselected');
    const [currentTotalBoxQuantity, setCurrentTotalBoxQuantity] = useState(0);
    const [boxInfoDisplay, setBoxInfoDisplay] = useState('none');
    const [reminderDisplay, setReminderDisplay] = useState('none');
    const [reminderSecondDisplay, setReminderSecondDisplay] = useState("none");

    const boxSizesList = useRef();
    const preselectedBoxSize = location.boxSize
    ? location.boxSize 
    : boxSizes.find(boxSizeToFind => boxSizeToFind.productCount === totalBoxQuantity) 
    ? boxSizes.find(boxSizeToFind => boxSizeToFind.productCount === totalBoxQuantity).size
    : null;

    useEffect(() => {
        if (preselectedBoxSize && preselectedBoxSize !== "unset") {
            const thisBoxSizeIndex = boxSizes.indexOf(boxSizes.find(boxSizeToFind => boxSizeToFind.size === preselectedBoxSize));
            const boxSizesElements = boxSizesList.current.children;

            setBoxSize(preselectedBoxSize);
            changeTotalBoxQuantityAndPrice(preselectedBoxSize);
            setBoxSizeBtnColor(boxSizesElements, boxSizesElements.item(thisBoxSizeIndex).children.item(0));
            showBoxInfo();
        }
    }, [])

    useEffect(() => {
        const newCurrentTotalBoxQuantity = boxQuantity.reduce((a, b) => a + b, 0);
        setCurrentTotalBoxQuantity(newCurrentTotalBoxQuantity);
    }, [boxQuantity])

    const setBoxSizeBtnColor = (boxSizesBtns, newBoxSizeBtn) => {
        for (const boxSizeBtn of boxSizesBtns) {
            let thisButtonElement = boxSizeBtn.children.item(0);

            if (thisButtonElement.classList.contains('active')) {
                thisButtonElement.classList.remove('active');
            }
        }
        newBoxSizeBtn.classList.add('active');
    }

    const toggleBoxSize = (event) => {
        const boxSizesElements = event.target.parentNode.parentNode.children;
        const boxSizeButton = event.target;
        setBoxSizeBtnColor(boxSizesElements, boxSizeButton);

        const boxSizeClicked = boxSizeButton.textContent;
        setBoxSize(boxSizeClicked);
        changeTotalBoxQuantityAndPrice(boxSizeClicked);
        showBoxInfo();
    }

    const changeTotalBoxQuantityAndPrice = (sizeClicked) => {
        const prevSize = boxSizes.find(boxSizeToFind => boxSizeToFind.size === boxSize)
        const newSize = boxSizes.find(boxSizeToFind => boxSizeToFind.size === sizeClicked)

        if (prevSize && prevSize.productCount > newSize.productCount) {
            setAndSaveToLocalStorage(() => {
                let newBoxQuantity = boxQuantity.map(() => 0);
                return newBoxQuantity;
            }, setBoxQuantity, "boxQuantity")
        }

        setAndSaveToLocalStorage(newSize.productCount, setTotalBoxQuantity, "totalBoxQuantity");
    }

    const showBoxInfo = () => {
        if (boxSize !== "unselected" || preselectedBoxSize) {
            setBoxInfoDisplay('block');
        }
    }

    const validateAndContinue = () => {
        if (currentTotalBoxQuantity !== totalBoxQuantity) {
            setReminderDisplay('block');
        }
        else {
            history.push({
                pathname: '/cart-check',
                from: "/mixed-box"
            });
        }
    }

    const handleChocolateBox = () => {
        if (currentTotalBoxQuantity !== totalBoxQuantity) {
            setReminderDisplay('block');
        }
        else {
            history.push({
                pathname: '/chocolate-box'
            });
        }
    }

    const changeQuantityOfThisProduct = (event) => {
        const thisProductElement = event.target.parentNode.parentNode;
        const productElements = thisProductElement.parentNode.children;
        const thisProductIndex = Array.prototype.indexOf.call(productElements, thisProductElement);
        const isIncrement = event.target.textContent.trim() === "+";
        const amountToChangeBy = isIncrement ? 1 : -1;

        if (boxSize === "unselected") {
            setReminderSecondDisplay("block");
        }
        else if (totalBoxQuantity >= currentTotalBoxQuantity + amountToChangeBy) {
            setReminderDisplay("none");
            setReminderSecondDisplay("none");
            setAndSaveToLocalStorage(() => {
                let newBoxQuantity = [...boxQuantity];
                if (newBoxQuantity[thisProductIndex] !== 0 || isIncrement) {
                    newBoxQuantity[thisProductIndex] = newBoxQuantity[thisProductIndex] + amountToChangeBy;
                }
                return newBoxQuantity;
            }, setBoxQuantity, "boxQuantity");
        }

        showBoxInfo();
    }

    const handleGoBack = () => {
        history.goBack();
    }

    return (
        <div className="mixed-box">
            <span onClick={handleGoBack} className="back">
                <i className="fas fa-long-arrow-alt-left" />
            </span>
            <h1>Mám chuť na<br />na poriadnu čokoládu!</h1>
            <h2>veľkosť boxu</h2>
            <ul ref={boxSizesList} className="select-box-size">
                {
                    boxSizes.map(boxSize => 
                        <MixedBoxBoxSize 
                            boxSize={boxSize.size}
                            toggleBoxSize={toggleBoxSize}
                        />
                    )
                }
            </ul>
            <h2>tyčinky v boxe</h2>
            <ul className="select-box-content">
                {
                    products.map((product, index) => {
                        if (!product.soldOut) {
                            return (
                                <MixedBoxProduct
                                    name={product.name.replace(/\b\w/g, l => l.toUpperCase())}
                                    changeQuantityOfThisProduct={changeQuantityOfThisProduct}
                                    quantity={boxQuantity[index]}
                                    halfDiscount={product.halfDiscount}
                                />
                            )
                        }
                    })
                }
            </ul>
            <h3 style={{ display: boxInfoDisplay }}>
                Zostávajúci počet tyčiniek v boxe:
                <strong>
                    {totalBoxQuantity - currentTotalBoxQuantity}
                </strong>
            </h3>
            {products.some(product => product.halfDiscount) && 
                <p className="is-discounted" style={{ textAlign: "center", fontFamily: "Open Sans Bold" }}>
                    Končí mi spotreba, máš ma so zľavou 50%
                </p>
            }
            <h4 style={{ display: boxInfoDisplay }}>Cena celkom: <strong>{totalPriceToString(totalPrice)}€</strong></h4>
            <span style={{
                color: 'red',
                display: reminderDisplay,
                textAlign: 'center',
                fontFamily: 'Open Sans Bold',
                marginTop: '2vh'
            }}
            >
                Uveďte správny počet tyčiniek podľa veľkosti boxu.
            </span>
            <span style={{
                color: 'red',
                display: reminderSecondDisplay,
                textAlign: 'center',
                fontFamily: 'Open Sans Bold',
                marginTop: '2vh'
            }}
            >
                Vyberte si veľkosť boxu.
            </span>
            <div className="continue">
                {!productsSecond.every(product => product.soldOut) && 
                    <button onClick={handleChocolateBox} style={{ display: boxInfoDisplay }}>Mám chuť aj na bonboniéru!</button>
                }
                <button onClick={validateAndContinue} style={{ display: boxInfoDisplay }}>Chcem platiť!</button>
            </div>
        </div>
    )
}

export default MixedBox
