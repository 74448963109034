import React, {useContext, useEffect, useRef, useState} from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { CartContext } from '../../../CartContext';
import products from "../../../constantData/products";

import { CHECKOUT, CHECKOUT_CASH } from "../../../config/endpoints";
import { orderNumberStringToBesteronVs, toCamelCase } from '../../../utils/';
import productsSecond from '../../../constantData/productsSecond';
import { CID } from '../../../constantData/besteron';
const URL_CHECKOUT = CHECKOUT;
const URL_CHECKOUT_CASH = CHECKOUT_CASH;

const CheckBuyerInformation = () => {
    const cartContext = useContext(CartContext);

    let location = useLocation();
    let history = useHistory();

    let boxQuantity = {};
    products.filter(({ soldOut }) => !soldOut).forEach(({ name }, index) => boxQuantity[toCamelCase(name)] = cartContext.boxQuantity[index]);
    products.filter(({ soldOut }) => soldOut).forEach(({ name }) => boxQuantity[toCamelCase(name)] = 0);

    const besteronElem = useRef(null);

    const [billingInformation, setBillingInformation] = useState(location.buyerInformation);
    const [deliveryInformation, setDeliveryInformation] = useState({
        firstName: "-",
        lastName: "-",
        address: "-",
        city: "-",
        zipCode: "-"
    });
    const [secondFormDisplay, setSecondFormDisplay] = useState("none");
    const [termsAgreed, setTermsAgreed] = useState(true);
    const [buttonMarginTop, setButtonMarginTop] = useState("5vh");
    const [clickedCheckout, setClickedCheckout] = useState(false);

    const firstNameInput = useRef();
    const lastNameInput = useRef();
    const adressInput = useRef();
    const cityInput = useRef();
    const zipCodeInput = useRef();
    const phoneInput = useRef();
    const emailInput = useRef();

    const deliveryFirstNameInput = useRef();
    const deliveryLastNameInput = useRef();
    const deliveryAddressInput = useRef();
    const deliveryCityInput = useRef();
    const deliveryZipCodeInput = useRef();

    useEffect(() => {
        firstNameInput.current.value = billingInformation.firstName;
        lastNameInput.current.value = billingInformation.lastName;
        adressInput.current.value = billingInformation.address;
        cityInput.current.value = billingInformation.city;
        zipCodeInput.current.value = billingInformation.zipCode;
        phoneInput.current.value = billingInformation.phone;
        emailInput.current.value = billingInformation.email;

        window.Besteron || (function (d) {
            let s, c, o = function () { o._.push(arguments) }; o._ = [];
    
            s = d.getElementsByTagName('script')[0]; c = d.createElement('script');
            c.type = 'text/javascript'; c.charset = 'utf-8'; c.async = false;
    
            c.src = 'https://client.besteron.com/inline/besteronInline.js';
            s.parentNode.insertBefore(c, s);
        })(document);
    }, [])

    const handleOnlinePayment = async () => {
        setClickedCheckout(prevState => !prevState);

        const paymentId = orderNumberStringToBesteronVs(location.orderId);

        const res = await window.fetch(URL_CHECKOUT, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                boxContent: boxQuantity,
                secondBoxContent: cartContext.productsSecondQuantity,
                totalBoxQuantity: cartContext.totalBoxQuantity,
                price: parseFloat(cartContext.totalPrice.replace(/,/g, "")),
                billingInfo: billingInformation,
                deliveryInfo: deliveryInformation,
                paymentMethod: location.paymentMethod,
                shippingMethod: location.shippingMethod,
                afterDiscount: location.afterDiscount,
                orderNumber: location.orderId,
                discountCode: location.discountCode,
                placeSelectedID: location.placeSelectedID,
                paymentId
            })
        });
        const json = await res.json();
        
        const paymentData = {
            cid: CID,
            amnt: cartContext.totalPrice.replace(",", "."),
            vs: paymentId,
            curr: '978',
            ru: 'https://simplychocolate.sk/paid',
            sign: json.signed,
            language: 'sk',
            paymentmethod: 'CARDANDBUTTON',
        };

        clear();
        window.Besteron.show(document, besteronElem.current, paymentData);
    }

    const changeBillingInformation = (event) => {
        setBillingInformation(() => {
            let newBillingInformation = billingInformation;
            newBillingInformation[event.target.name] = event.target.value;
       
            return newBillingInformation;
        });
    }

    const changeDeliveryInformation = (event) => {
        setDeliveryInformation(() => {
            let newDeliveryInformation = deliveryInformation;
            newDeliveryInformation[event.target.name] = event.target.value;

            return newDeliveryInformation;
        });
    }

    const handleCheckboxChange = () => {
        if (secondFormDisplay === "none") {
            setSecondFormDisplay("block");
            setButtonMarginTop("4vh");
        }
        else {
            setSecondFormDisplay("none");
            setButtonMarginTop("10vh");
        }
    }

    const toggleTermsAgreed = () => {
        setTermsAgreed(!termsAgreed);
    }

    const handleCashCheckout = async () => {
        const response = await fetch(URL_CHECKOUT_CASH, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                boxContent: boxQuantity,
                secondBoxContent: cartContext.productsSecondQuantity,
                totalBoxQuantity: cartContext.totalBoxQuantity,
                price: parseFloat(cartContext.totalPrice.replace(/,/g, "")),
                billingInfo: billingInformation,
                deliveryInfo: deliveryInformation,
                paymentMethod: location.paymentMethod,
                shippingMethod: location.shippingMethod,
                afterDiscount: location.afterDiscount,
                orderNumber: location.orderId,
                discountCode: location.discountCode,
                placeSelectedID: location.placeSelectedID
            })
        });

        clear();
        const cashCheckoutResponse = await response.json();
        history.push({
            pathname: cashCheckoutResponse.url
        });
    };

    const clear = () => {
        cartContext.setBoxQuantity(products.map(() => 0));
        cartContext.setTotalBoxQuantity(0);
        cartContext.setProductsSecondQuantity(productsSecond.map(() => 0));
        cartContext.setTotalPrice(0.00);
        cartContext.setEnteredCartAndLeft(false);

        localStorage.clear();
    }

    const handleContinue = () => {
        if (termsAgreed) {
            return location.paymentMethod === "cash" ? handleCashCheckout() : handleOnlinePayment() 
        }
    }

    const differentDeliveryElement = location.shippingMethod === "courier" ? 
    <>
        <label><input onChange={handleCheckboxChange} type="checkbox" />Na doručenie použiť odlišnú adresu</label>
        <div style={{ display: secondFormDisplay }} className="different-address-delivery">
            <form>
                <input onChange={changeDeliveryInformation} ref={deliveryFirstNameInput} type="text" placeholder="Meno" name="deliverToFirstName" />
                <input onChange={changeDeliveryInformation} ref={deliveryLastNameInput} type="text" placeholder="Priezvisko" name="deliverToLastName" />
                <input onChange={changeDeliveryInformation} ref={deliveryAddressInput} type="text" placeholder="Adresa" name="deliverToAddress" />
                <input onChange={changeDeliveryInformation} ref={deliveryCityInput} type="text" placeholder="Mesto" name="deliverToCity" />
                <input onChange={changeDeliveryInformation} ref={deliveryZipCodeInput} type="text" placeholder="PSČ" name="deliverToZipCode" />
            </form>
        </div>
    </>
    : null;

    if (location.buyerInformation && cartContext.boxQuantity && cartContext.totalBoxQuantity || cartContext.productsSecondQuantity) 
    return (
        <div className="check-buyer-info">
            {clickedCheckout && <div id="shop-iframe" className="shop-iframe" ref={besteronElem}></div>}
            <h1>Máme správne údaje?</h1>
            <h3>(Upravíte kliknutím na údaj)</h3>
            <div className="billing-information">
                <ul>
                    <li>Meno:</li>
                    <li>Priezvisko:</li>
                    <li>Ulica:</li>
                    <li>Mesto:</li>
                    <li>PSČ:</li>
                    <li>Telefónne číslo:</li>
                    <li>Email:</li>
                </ul>
                <form>
                    <input onChange={changeBillingInformation} type="text" name="firstName" ref={firstNameInput} />
                    <input onChange={changeBillingInformation} type="text" name="lastName" ref={lastNameInput} />
                    <input onChange={changeBillingInformation} type="text" name="address" ref={adressInput} />
                    <input onChange={changeBillingInformation} type="text" name="city" ref={cityInput} />
                    <input onChange={changeBillingInformation} type="text" name="zipCode" ref={zipCodeInput} />
                    <input onChange={changeBillingInformation} type="text" name="phone" ref={phoneInput} />
                    <input onChange={changeBillingInformation} type="email" name="email" ref={emailInput} />
                </form>
            </div>
            {differentDeliveryElement && differentDeliveryElement}
            <div className="flex">
                <label className="terms-agree">
                    <input type="checkbox" checked={termsAgreed} onClick={toggleTermsAgreed} />
                    Súhlasím s<a href="/obchodne-podmienky" target="_blank">&nbsp;obchodnými podmienkami</a>
                </label>
                <button 
                    onClick={handleContinue} 
                    style={{ marginTop: buttonMarginTop }} 
                    className="confirm"
                >
                    Objednať
                </button>
            </div>
        </div>
    )
    else return (
        <Redirect to="/" />
    )
}

export default CheckBuyerInformation
