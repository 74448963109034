import React, {useContext} from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import CartCheckProduct from './CartCheckProduct';
import products from '../../../constantData/products';
import productsSecond from '../../../constantData/productsSecond';
import { CartContext } from '../../../CartContext';
import { totalPriceToString } from '../../../utils';

const CartCheck = () => {
    const {boxQuantity, totalPrice, totalBoxQuantity, productsSecondQuantity} = useContext(CartContext);

    let history = useHistory();

    const price = totalPriceToString(totalPrice);

    const handleContinue = () => {
        history.push({
            pathname: "/buyer-info",
        })
    }

    const handleBack = (event) => {
        event.preventDefault();
        history.goBack();
    }

    if (totalBoxQuantity || productsSecondQuantity.some(quantity => quantity > 0)) return (
        <div className="cart-check">
            <h1>Lásku si za peniaze nekúpiš,<br />čokoládu ÁNO!</h1>
            <h2>Už skoro u teba doma...</h2>
            <ul className="products-to-purchase">
                {
                    boxQuantity.map((quantity, productIndex) =>
                        quantity ? 
                        <CartCheckProduct
                            name={products[productIndex].name}
                            quantity={quantity}
                            key={productIndex}
                        />
                        : null
                    )
                }
                {
                    productsSecondQuantity.map((quantity, productIndex) =>
                        quantity ?
                        <CartCheckProduct
                            name={productsSecond[productIndex].firstLine + productsSecond[productIndex].secondLine}
                            quantity={quantity}
                            key={productIndex}
                        />
                        : null
                    )
                }
                <li>Celkom: <strong>{price}&nbsp;€</strong></li>
            </ul>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <a
                    href="/"
                    onClick={handleBack}
                    style={{
                        fontFamily: 'Open Sans',
                        textDecoration: 'underline',
                        marginRight: '4em',
                        cursor: 'pointer',
                        fontSize: '1.05em'
                    }}
                >
                    Späť
            </a>
                <button onClick={handleContinue} className="continue">Pokračovať</button>
            </div>
        </div>
    )
    else return (
        <Redirect to="/" />
    )
}

export default CartCheck