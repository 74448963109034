import React, { useContext, useState } from "react";
import OpenProductNutrition from "./OpenProductNutrition";
import ProductNutritionDesktop from "../desktop/ProductNutritionDesktop";
import ProductNutrition from "../mobile/ProductNutrition";
import ProductsSecondCount from "./ProductsSecondCount";
import { isMobile } from "react-device-detect";
import { useHistory } from "react-router";
import { CartContext } from "../../CartContext";
import Typical from "react-typical";
import { formatPriceForUI } from "../../utils";

const ProductSecond = (props) => {
  const { productsSecondQuantity } = useContext(CartContext);

  const history = useHistory();

  const [nutritionDisplay, setNutritionDisplay] = useState("none");
  const [cartDisplay, setCartDisplay] = useState("none");

  const originalPrice = formatPriceForUI(props.price);
  const device = isMobile ? "mobile" : "desktop";

  const toggleNutritionDisplay = () => {
    if (nutritionDisplay === "none") {
      setNutritionDisplay("block");
    } else {
      setNutritionDisplay("none");
    }
  };

  const toggleCartDisplay = () => {
    if (cartDisplay === "none") {
      setCartDisplay("block");
    } else {
      setCartDisplay("none");
    }
  };

  const handleContinueShopping = () => {
    if (productsSecondQuantity.some((quantity) => quantity > 0)) {
      history.push({
        pathname: "/mixed-box",
        from: "/#products",
      });
    }
  };

  const handleCheckout = () => {
    if (productsSecondQuantity.some((quantity) => quantity > 0)) {
      history.push({
        pathname: "/cart-check",
        from: "/#products",
      });
    }
  };

  return (
    <>
      <div
        className="desktop-section product-second"
        style={{
          background: `url('/img/${device}/${props.name.replace(
            /\s+/g,
            "-"
          )}-bg.webp')`,
        }}
      >
        <div className="product-info">
          <h1
            className="heading"
            style={{ color: props.color, position: "relative" }}
          >
            {props.firstLine}
            <br />
            {props.isHalfDiscounted && !props.isSoldOut && (
              <img src="/img/mutual/sale.png" alt="" className="sale-icon" />
            )}
            <Typical
              steps={["", 1400, props.secondLine, 1200]}
              loop={Infinity}
              wrapper="div"
            />
          </h1>
          <h2 className="price" style={{ display: "flex" }}>
            {props.isSoldOut ? (
              <div>vypredané</div>
            ) : (
              <>
                <div
                  style={{
                    color: props.priceColor,
                    textDecoration: props.isHalfDiscounted
                      ? "line-through"
                      : "",
                  }}
                >
                  {originalPrice}
                </div>
                {props.isHalfDiscounted && (
                  <strong
                    style={{
                      fontSize: "1.2em",
                      marginLeft: "1em",
                      color: "#ff6771",
                    }}
                    className="new-price"
                  >
                    {formatPriceForUI(props.price / 2)}
                  </strong>
                )}
              </>
            )}
          </h2>
          <p className="description">
            {props.pieces} kusov zmiešaných čokoládových bonbónov rôznych chutí.
          </p>
          <img
            className="main-img"
            src={`/img/mutual/${props.name.replaceAll(" ", "-")}.webp`}
            alt=""
          />
          <OpenProductNutrition
            handleClick={toggleNutritionDisplay}
            position="relative"
            fontSize="1em"
          />
        </div>
        <img
          src={`/img/mutual/${props.name.replaceAll(" ", "-")}.webp`}
          alt=""
        />
        {!props.isSoldOut && (
          <button
            className="product-second-cta-animation"
            onClick={toggleCartDisplay}
            style={{ color: "#fff", backgroundColor: props.color }}
          >
            chcem bonboniéru!
          </button>
        )}
        {isMobile ? (
          <ProductNutrition
            nutritionDisplay={nutritionDisplay}
            toggleNutritionDisplay={toggleNutritionDisplay}
            nutrition={props.nutrition}
            nutritionDesc={props.nutritionDesc}
            isChocolateBox={true}
          />
        ) : (
          <ProductNutritionDesktop
            nutritionDisplay={nutritionDisplay}
            toggleNutritionDisplay={toggleNutritionDisplay}
            nutrition={props.nutrition}
            nutritionDesc={props.nutritionDesc}
            isChocolateBox={true}
          />
        )}
      </div>
      <div className="product-second-cart" style={{ display: cartDisplay }}>
        <i className="fas fa-times" onClick={toggleCartDisplay} />
        <h1>máš chuť na čokolásku?</h1>
        <p>objednaj si Simply Chocolate už dnes!</p>
        <ProductsSecondCount />
        <button onClick={handleContinueShopping}>mám chuť aj na tyčinky</button>
        <button onClick={handleCheckout}>mám všetko a chcem platiť</button>
      </div>
    </>
  );
};

export default ProductSecond;
