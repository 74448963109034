import React, {useContext, useState} from "react";
import { useHistory } from "react-router";
import ProductsSecondCount from "./ProductsSecondCount";
import { CartContext } from "../../CartContext";
import productsSecond from "../../constantData/productsSecond";

const ChocolateBox = () => {
    const {productsSecondQuantity} = useContext(CartContext);

    let history = useHistory();

    const [reminderDisplay, setReminderDisplay] = useState("none");

    const handleCheckout = () => {
        if (productsSecondQuantity.some(quantity => quantity > 0)) {
            history.push({
                pathname: "/cart-check"
            })
        }
        else {
            setReminderDisplay("block");
        }
    }

    const handleGoBack = () => {
        history.push({
            pathname: "/mixed-box"
        })
    }

    return (
        <div className="chocolate-box">
            <span onClick={handleGoBack} className="back">
                <i className="fas fa-long-arrow-alt-left" />
            </span>
            <h1>
                Mám chuť aj na bonboniéry!
            </h1>
            <h2>
                Objednaj si Simply Chocolate už dnes!
            </h2>
            <ProductsSecondCount />
            <span style={{
                color: 'red',
                display: reminderDisplay,
                textAlign: 'center',
                fontFamily: 'Open Sans Bold',
                marginTop: '2vh'
            }}
            >
                Vyberte si množstvo bonboniér.
            </span>
            {productsSecond.some(product => product.halfDiscount) && <p style={{ color: "#e51818cc", textAlign: "center", fontFamily: "Open Sans Bold" }} >Bonboniéram končí spotreba, máš ich so zľavou 50%</p>}
            <button onClick={handleCheckout}>
                Mám všetko a chcem platiť
            </button>
        </div>
    )
}

export default ChocolateBox
