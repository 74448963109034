import React from 'react';

const ObchodnePodmienky = () => {


    return (
        <object 
            data="/pdf/vseobecne-obchodne-podmienky.pdf" 
            type="application/pdf"
            width="100%"
            height="100%"
        >

        </object>
    )
}

export default ObchodnePodmienky